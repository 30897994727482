import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { CountryService } from '@core/admin/services/country.service';
import { EmployeesService } from '@core/admin/services/employees.service';
import { SpecialitiesService } from '@core/admin/services/specialities.service';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { NoAuthGuard } from '@core/auth/guards/noAuth.guard';
import { LayoutComponent } from '@layout/layout.component';
import { initialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('@pages/auth/forgot-password/forgot-password.routes'),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('@pages/auth/reset-password/reset-password.routes'),
      }
    ]
  },
  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            '@pages/auth/confirmation-required/confirmation-required.routes'
          ),
      },

      {
        path: 'sign-in',
        loadChildren: () => import('@pages/auth/sign-in/sign-in.routes'),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('@pages/auth/sign-up/sign-up.routes'),
        resolve: {
          s: () => inject(SpecialitiesService).findAll({ take: 10000 }),
          c: () => inject(CountryService).findAll({ take: 10000 }),
        },
      },
      {
        path: 'activate',
        loadChildren: () => import('@pages/auth/activation/activation.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('@pages/auth/sign-out/sign-out.routes'),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('@pages/auth/unlock-session/unlock-session.routes'),
      },
    ],
  },


  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'thin',
    },
    resolve: {
      initialData: initialDataResolver,
      doc: () => inject(EmployeesService).findAll({ take: 1000 }),
      c: () => inject(CountryService).findAll({ take: 10000 }),
    },
    children: [
      {
        path: '',
        loadChildren: () => import('@projects/my3ms/my3ms.routes'),
      },
    ],
  },
];
